@font-face {
  font-family: 'Cormorant';
  src: url('../public/assets/fonts/Cormorant-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Cormorant-Bold';
  src: url('../public/assets/fonts/Cormorant-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Cormorant-Italic';
  src: url('../public/assets/fonts/Cormorant-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Cormorant-Light';
  src: url('../public/assets/fonts/Cormorant-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../public/assets/fonts/PlayfairDisplay-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: url('../public/assets/fonts/PlayfairDisplay-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PlayfairDisplay-Italic';
  src: url('../public/assets/fonts/PlayfairDisplay-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Futura';
  src: url('../public/assets/fonts/FuturaPTMedium.otf') format('opentype');
}
@font-face {
  font-family: 'FuturaBook';
  src: url('../public/assets/fonts/FuturaPTBook.otf') format('opentype');
}
@font-face {
  font-family: 'FuturaBold';
  src: url('../public/assets/fonts/FuturaPTBold.otf') format('opentype');
}
@font-face {
  font-family: 'FuturaLight';
  src: url('../public/assets/fonts/FuturaPTLight.otf') format('opentype');
}
@font-face {
  font-family: 'MillerDisplay';
  src: url('../public/assets/fonts/Miller-Display.otf') format('opentype');
}
@font-face {
  font-family: 'MillerDisplayItalic';
  src: url('../public/assets/fonts/Miller-DisplayItalic.otf') format('opentype');
}

html, body {
  overflow-y: scroll !important;
}

.App {
  text-align: center;
}
body{
  font-family: 'FuturaBook';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.upper{
  /* font-family: 'Cormorant'; */
  text-transform: uppercase;

}

.title{
  font-family: 'Cormorant-Light';
  color: #011640;

}
.title-3{
  font-family: 'Cormorant-Bold';
  color: #011640;

}
.titleheavy{
  font-family: 'Cormorant-Bold', serif;
  color: #011640;
}
.titleitalic{
  font-family: 'Cormorant-Italic', serif;
  color: #011640;
}
.titleline{
  border-bottom: 2px solid purple;
  font-family: 'Cormorant-Light';
  color: #011640;
}
.anchor{
  text-decoration: none;
}
a:link {
  text-decoration: none;
  color: #011640;
}

a:visited {
  text-decoration: none;
  color: #011640;

}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
  /* color: #ff00b3;  */
}
.title2{
  font-family: 'PlayfairDisplay-Italic';
  color:#282c34
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal-backdrop.show {
  display: none !important;
}
#myModal {
  background: #0009;
}

#occupancy-control {
  display: flex;
  justify-content: space-between;
}

.fade { opacity: 1; }

.breadcrumb { background: none }

.mobile_booking_wrapper { left: 0; }

#booking-widget .vue-btnbb { padding: 0; }

/*** Side card */
.vue-offers-card .card-img-top:hover {
  transform: scale(4) translate(-5rem,-3rem);
  transition: transform .5s ease;
}

#booking-widget .vue-right-side {  margin: unset; }

#booking-widget #vue-dropdown-home.dropdown-menu { width: fit-content; }

svg,
#booking-widget h1.room-title,
#booking-widget h2 {
  margin-bottom: 0 !important;
  color: var( --e-global-color-accent );
  text-align: left;
}

#booking-widget button.pnotify-action-button { color: #011640  !important; }

#booking-widget .vue-date-box label,
#booking-widget .content-body,
#booking-widget .vue-content-body, 
#booking-widget p, #booking-widget span {
  font-family: FuturaBook, sans-serif !important;
}

#booking-widget .reserveport-form-wrap h3,
#booking-widget h1.room-title,
#booking-widget h2, .modal-title, 
#booking-widget .vue-side_top_card h3 {
  font-family: 'Cormorant-Light', sans-serif;
  font-weight: 700;

}

#booking-widget a, #booking-widget .show-cp:hover,
#booking-widget .vue-show-details:hover,
#booking-widget .vue-show-tc:hover,
#vue-havepromo.vue-horizontal,
#booking-widget h1.room-title:hover {
  color: #011640;
}

a:hover { text-decoration: none; }

#booking-widget h2 { font-size: 2.5rem; }

label.checkmark, #booking-widget a,
#booking-widget .room-view .room .room-details { 
  position: relative;
}

#booking-widget .vue-date-box[data-v-07c68727] {
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, .1);
  transition: all 0.3s ease-in-out;
}

#booking-widget .icon { right: 2rem; }

#booking-widget label { font-weight: normal; }


.mx-calendar-content .cell.active,
#vue-toggle_residency .vue-btn, 
#booking-widget button,
#booking-widget .btn-orange-solid,
#booking-widget .btn-solid,
#booking-widget .sub-total .btn.btn-solid,
#booking-widget .vue-btn-proceed,
#booking-widget #summery-submit.btn-proceed {
  font: 600 .8em Roboto;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: #011640  !important;
  transition: all 0.3 ease;
  font-weight: 500;
}

#booking-widget #vue-btn-search, #booking-widget #vue-btn, #booking-widget .btn-solid {
  text-transform: uppercase;
  font-size: .8em;
}

#vue-toggle_residency .vue-btn.active,
#booking-widget .btn-orange-solid:hover,
#vue-toggle_residency .vue-btn.active:hover,
#booking-widget .btn-solid:hover,
#booking-widget .sub-total .btn.btn-solid:hover,
#booking-widget .vue-btn-proceed:hover, 
button#submitFormBtn:hover,
#vue-btn-managebooking:hover,
#booking-widget #summery-submit.btn-proceed:hover {
  border: 2px solid #011640 !important;
}

#booking-widget .hotel .notify-container, #booking-widget .reserveport-iframe-wrap .notify-container,
#booking-widget #alert-msg.ui-pnotify.ui-pnotify-inline {
  background: #011640 !important;
}

#modalbookingForm { padding-top: 4rem; }
#booking-widget .responsive {
  max-width: unset;
}

.owl-carousel .owl-item img, #booking-widget .room-full-slider img {
  max-height: 400px;
  object-fit: cover;
  margin: auto;
}

#bedimg, booking-widget .pull-right img {
  filter: brightness(0) saturate(100%) invert(4%) sepia(96%) saturate(4604%) hue-rotate(219deg) brightness(99%) contrast(99%);
}

#booking-widget .room-title-details.row,
#booking-widget .room-title-details .pull-right, figure {
  display: flex;
}

#booking-widget .room-title-details .pull-right .btn-solid {
  margin-left: auto;
  left: 0;
  bottom: 0;
}

#image-lightbox {
  display: flex;
  justify-content: center;
}

#image-lightbox .row { margin-left: 0 !important; }

#booking-widget #vue-smallScreen input, #booking-widget #vue-smallScreen .vue-small_large_select {
  text-transform: uppercase;
  font-size: 1em;
}
#booking-widget input[type="checkbox"] { display: inline; }

#booking-widget .close {
padding: 4px 6px;
background: white;
border: 1px solid #011640;
opacity: 1;
}

#modalbookingForm button.close { border: none; }

#image-lightbox .modal-dialog, #image-lightbox .vue-lightbox img {
  width: max-content;
  max-width: 800px;
}


@media only screen and (max-width: 600px) {
  #image-lightbox .modal-dialog {
    position: relative;
    transform: translate(0%, 0%) !important;
    left: 0;
    max-width: none;
  }
  .vue-mobile .col-md-6.col-xs-6 {
    width: 50%;
  }

  #image-lightbox .row {
    margin-right: 0;
  }

  #image-lightbox .vue-lightbox img {
    width: 100%;
  }

  .room-full-slider {
      width: 576px;
  }
}


search-availability { 
  margin: 0 auto; 
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

a { text-decoration: none; }

#occupancy-control {
    display: flex;
    justify-content: space-between;
}

#app #booking-widget .reserveport-form-wrap {
    padding: 2rem;
}
#app #booking-widget, #booking-widget .vue-date-box label, #booking-widget .form-group input {
  font: 1rem Roboto, sans-serif !important;
}
#booking-widget .vue-date-box input.vue-coupon_code_screen {
  max-width: fit-content;
}
#booking-widget .vue-search-box {
  margin-bottom: 0 !important;
}
#booking-widget #vue-btn-search.btn-orange-solid { 
  font: 600 .8em Roboto;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: var( --e-global-color-primary ) !important; 
  border: 1px solid var( --e-global-color-primary );
}
#booking-widget #vue-btn-search.btn-orange-solid:hover{ 
  background-color: var( --e-global-color-accent) !important; 
  border-color: var( --e-global-color-accent);
}
a#havepromo.vue-horizontal {
  color: var(--e-global-color-primary) !important;
  text-transform: capitalize;
  position: relative;
}